import React from 'react'

function NotFound() {
  return (
    <div className='data-container'>
        {/* <div className="window-content" > */}

          <h1>404</h1>
          <br />
          <h3>Page Not Found</h3>
        {/* </div> */}

    </div>
  )
}

export default NotFound